/* VARIABLES */

$blanc:#fff;
$couleur_principale:#D82937;
$couleur_secondaire:darken($couleur_principale, 20%);
$couleur_tierce:darken($couleur_secondaire, 10%);
$gris:#262525;
$gris-clair:#E8E8E8;
$noir:#000;

//General

$body_background:$blanc;
$body_color:$noir;
$content_background:$blanc;
$titre_border_color:$couleur_principale;
$titre_color:$couleur_principale;
$link_color:$couleur_tierce;

//Header

$header_fond:$couleur_principale;
$slogan_color:$blanc;

//Boutons

$boutons_color:$blanc;
$boutons_color_hover:$blanc;
$boutons_border_color:$blanc;
$boutons_border_color_hover:$blanc;
$boutons_background:$gris;
$boutons_background_hover:$couleur_tierce;

//Menu
$fond_barre_menu:$gris;
$fond_barre_menuS:$gris;
$border_menuS:$couleur_principale;
//Menu principal
$menu_color:$blanc;
$menu_color_hover:$blanc;
$menu_background_hover:$couleur_principale;
$menu_color_sous:$couleur_principale;
$menu_color_sous_hover:$blanc;
$menu_background_sous:$blanc;
$menu_background_sous_hover:$couleur_principale;
//Menu secondaire
$menuS_color:$blanc;
$menuS_color_hover:$blanc;
$menuS_background_hover:$couleur_principale;
//Nav ariane
$ariane_border:$couleur_principale;
$ariane_color:$noir;

//Produits / Slider / Galerie

$mask_fond:$couleur_principale;
$mask_color:$blanc;

// Produits

$produits_fond:$couleur_principale;
$produits_color:$blanc;
$produits_oldprix:$noir;
$produits_border:$couleur_principale;

$select_border:$couleur_principale;
$select_background:$couleur_principale;
$select_color:$blanc;

// Produits / Fiches produits

$remise_background:$couleur_principale;
$remise_color:$blanc;
$etiquette_background:$blanc;
$etiquette_color:$couleur_principale;

//Fiches produits

$fiches_h4_border:$couleur_principale;
$fiches_description_background:$gris-clair;
$fiches_description_color:$noir;
$fiches_prix_border:$couleur_secondaire;
$fiches_oldprix:$noir;
$fiches_prixprod:$noir;
$fiches_titres_background:$couleur_principale;
$fiches_titres_color:$blanc;
$fiches_sociaux_border:$couleur_secondaire;

//Livre d'or

$lo_background:$couleur_principale;
$lo_color:$blanc;
$lo_titre_color:$noir;

//Contact

$contact_map_border:$couleur_principale;
$form_input_border:rgba(0,0,0,.1);
$form_input_border_hover:$couleur_principale;
$form_input_background:$select_border;
$form_input_label_background:$couleur_principale;
$form_input_color:$select_color;

//Footer
$footer_border:$couleur_principale;
$footer_background:$gris;
$footer_color:$blanc;

//Slider

$slider_color:$gris;
$slider_background:$blanc;
$slider_color_hover:$blanc;
$slider_background_hover:$gris;

//Panier

$panier_background:$gris;
$panier_border:$gris;
$panier_titre-color:$blanc;
$panier-content-background:$blanc;
$panier-content-color:$noir;
$panier_count_background:$noir;
$napier_count_color:$blanc;

//Flux panier

$fp_bouton_background:$couleur_principale;
$fp_bouton_background_hover:$couleur_tierce;
$fp_bouton_color:$blanc;
$fp_color:$noir;

@import "design/683series/declinaison";